import axios from "axios";

const instance = axios.create({
  // baseURL: "http://localhost:3000/",  //Local Environment
  baseURL: "https://staging.archives.quicksand.co.in/backend", // Production Environment
});

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
