import instance from '../api/securedInstance'

// const BASE_URL = 'http://localhost:3000';
const BASE_URL = "https://staging.archives.quicksand.co.in/backend"; // Production Environment

export function isLoggedin() {
  return localStorage.getItem('token') !== null;
}

export function getHeaders() {
  const token = localStorage.getItem('token');
  return { 'Authorization': 'Bearer ' + token };
}

export function storeToken(token) {
  localStorage.setItem('token', token);
}

export function create(data) {
  return instance.post(`${BASE_URL}/post-googleuser`, data);
}

export function getAllGoogleUser() {
  return instance.get(`${BASE_URL}/googleusers`);
}

export function getAllEmployees() {
  return instance.get(`${BASE_URL}/employees`, { headers: getHeaders() });
}

export function getGoogleUserById(token) {
  return instance.get(`${BASE_URL}/getgoogleuserbyid/${token}`);
}

export function findOneGoogleUser(email) {
  let obj = { email: email };
  return instance.post(`${BASE_URL}/findonegoogleuser`, obj);
}

//Get all users
export function getAllUsers() {
  return instance.get(`${BASE_URL}/users`);
}

export function getUserByToken(token) {
  return instance.get(`${BASE_URL}/getuserbytoken/${token}`);
}

export function getLatestCurrencyValue() {
  return instance.get(`${BASE_URL}/getlatestcurrrate`);
}

export function GoogleUserFindAndUpdate(email) {
  let obj = { email: email };
  return instance.post(`${BASE_URL}/googleuser-find-update`, obj);
}