import instance from "../api/securedInstance";

class GoogleDriveService {
  getHeaders() {
    const token = localStorage.getItem("token");
    return { Authorization: "Bearer " + token };
  }

  getDrive() {
    const timestamp = new Date().getTime();
    return instance.get("drive?timestamp=" + timestamp);
  }

  getDriveFromDb(projectId) {
    const obj = { project_id: projectId };
    return instance.post("drive-from-db", obj, { headers: this.getHeaders() });
  }

  listDriveContents(folderId) {
    const obj = { folderId: folderId };
    return instance.post("list-drives", obj, { headers: this.getHeaders() });
  }

  getDriveFolder(folderId) {
    const obj = { folder_id: folderId };
    return instance.post("drive", obj, { headers: this.getHeaders() });
  }

  shareDrive(folderId, partnersemail) {
    const post_data = { folderId: folderId, email_ids: partnersemail };
    return instance.post("share_gd_folder", post_data, {
      headers: this.getHeaders(),
    });
  }

  folderToNode(obj) {
    return instance.post("create_gd_folder", obj, {
      headers: this.getHeaders(),
    });
  }

  createGoogleDriveParentName(obj) {
    return instance.post("create-gd-parent-folder", obj, {
      headers: this.getHeaders(),
    });
  }

  getLinkedFolders(projectId) {
    return instance.get("get-linked-folders/" + projectId, {
      headers: this.getHeaders(),
    });
  }

  getFolderDetails(folderId) {
    return instance.get("folder-details/" + folderId, {
      headers: this.getHeaders(),
    });
  }

  linkGoogleDriveFolder(projectId, folderDetails) {
    const payload = {
      project_id: projectId,
      folder_details: folderDetails.data,
    };
    return instance.post("link-google-drive-folder", payload, {
      headers: this.getHeaders(),
    });
  }
  addMilestone(projectId, milestoneDetails) {
    console.log("milestoneDetails", milestoneDetails);
    return instance.post(
      "milestones",
      { ...milestoneDetails, project_id: projectId },
      { headers: this.getHeaders() }
    );
  }

  updateMilestone(milestoneId, milestoneDetails) {
    return instance.put(`milestones/${milestoneId}`, milestoneDetails, {
      headers: this.getHeaders(),
    });
  }

  deleteMilestone(milestoneId) {
    return instance.delete(`milestones/${milestoneId}`, {
      headers: this.getHeaders(),
    });
  }

  getMilestones(projectId) {
    return instance.get(`milestones/${projectId}`, {
      headers: this.getHeaders(),
    });
  }

  getMilestone(milestoneId) {
    return instance.get(`milestone/${milestoneId}`, {
      headers: this.getHeaders(),
    });
  }

  getLinkedTimelines(projectId) {
    console.log("projectId", projectId);
    return instance.get(`get-linked-timelines/${projectId}`, {
      headers: this.getHeaders(),
    });
  }

  linkTimelineSheets(projectId, timelineDetails) {
    console.log("timelineDetails", timelineDetails);
    console.log("projectId", projectId);
    return instance.post(
      "link-timeline-sheets",
      {
        project_id: projectId,
        timeline_details: timelineDetails,
      },
      {
        headers: this.getHeaders(),
      }
    );
  }

  updateLinkedTimeline(projectId, timelineDetails) {
    console.log("Updating")
    return instance.put(
      `update-linked-timeline/${projectId}`,
      {
        timeline_details: timelineDetails,
      },
      {
        headers: this.getHeaders(),
      }
    );
  }
}

const googleDriveService = new GoogleDriveService();
export default googleDriveService;
