import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FinanceTabs from "../ProjectDetailsTabs/FinanceTabs";
import KeyLearningsTab from "../ProjectDetailsTabs/KeyLearningsTabs";
import ManagementTab from "../ProjectDetailsTabs/ManagementTabs";
import projectService from "../../services/ProjectService";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import GoogleDriveTab from "../ProjectDetailsTabs/GoogleDriveTabs";

const ProjectTabsHandler = ({
  isMobile,
  selectedTab,
  setSelectedTab,
  handleTabMobileChange,
  handleTabChange,
  uniqueTeamMembers,
  driveFolders,
  sortOption,
  handleSortChange,
  handleFolderClick,
  handleFolderShare,
  project_id,
  projectDetail,
  editing,
  setEditing,
  sheetIdInitial,
  setSheetIdInitial,
  sheetData,
  setSheetData,
  allInternalTeam,
  allExternalTeam,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const createdUser = projectDetail?.created_username ? projectDetail?.created_username : ""
  const userEmail = localStorage.getItem("userEmail");

  const userName = localStorage.getItem("userName");
  const adminUserInTeam =
    projectDetail?.internalTeam.some(
      (member) =>
        member.email === userEmail &&
        ["project-manager", "project-partner", "project-principal"].includes(
          member.slug
        ) &&
        member.status === "active"
    ) || createdUser === userName;
  



  useEffect(() => {
    setSelectedTab(0);
  }, []);

  const handleNavigateHome = () => {
    navigate("/");
  };

  const handlePublishProject = async () => {
    try {
      const confirmation = await Swal.fire({
        text: "Are you sure you want to publish the project?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      });

      if (confirmation.isConfirmed) {
        setLoading(true);
        const updatedProject = {
          ...projectDetail,
          publishproject: "y",
        };

        await projectService.updateProjectByProjectId(
          updatedProject,
          projectDetail._id
        );

        projectDetail.publishproject = "y";

        setLoading(false);

        Swal.fire({
          text: "Project has been published",
          icon: "success",
        }).then(() => {
          navigate("/");
        });
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error publishing project:", error);
      Swal.fire({
        text: "Failed to publish project",
        icon: "error",
      });
    }
  };

  useEffect(() => {
    setSelectedTabIndex(selectedTab);
  }, [selectedTab]);

  return (
    <>
      <Grid container spacing={3}>
        <div style={{ marginLeft: "1rem", marginTop: "1.5rem", width: "100%" }}>
          {isMobile ? (
            <Select value={selectedTab} onChange={handleTabMobileChange}>
              <MenuItem value={0}>Management</MenuItem>
              <MenuItem value={1}>Key Learning</MenuItem>
              <MenuItem value={2}>Finance</MenuItem>
            </Select>
          ) : (
            <>
              <Tabs
                textColor="primary"
                indicatorColor="primary"
                value={selectedTab}
                onChange={(event, newValue) => {
                  setSelectedTabIndex(newValue);
                  handleTabChange(event, newValue);
                }}
              >
                <Tab
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    minWidth: "15rem",
                    fontFamily: "Poppins",
                    color: selectedTabIndex === 0 ? "#5564b4" : "black",
                    backgroundColor:
                      selectedTabIndex === 0 ? "#EAEDFE" : "transparent",
                  }}
                  label="Management & Ops"
                />
                {adminUserInTeam && (
                  <Tab
                    style={{
                      fontWeight: "600",
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      minWidth: "10rem",
                      color: selectedTabIndex === 1 ? "#5fae77" : "black",
                      backgroundColor:
                        selectedTabIndex === 1 ? "#E3F6E9" : "transparent",
                    }}
                    label="Finance"
                  />
                )}
                <Tab
                  style={{
                    fontWeight: "600",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                    minWidth: "15rem",
                    color:
                      selectedTabIndex === (adminUserInTeam ? 2 : 1)
                        ? "#e9865d"
                        : "black",
                    backgroundColor:
                      selectedTabIndex === (adminUserInTeam ? 2 : 1)
                        ? "#ffe6dc"
                        : "transparent",
                  }}
                  label="Key Learning"
                />
              </Tabs>
              <div
                style={{
                  borderBottom: "2px solid #ccc",
                  maxWidth: "40cfdgbbtrem",
                }}
              ></div>
            </>
          )}
          {selectedTabIndex === 0 && (
            <ManagementTab
              uniqueTeamMembers={uniqueTeamMembers}
              allInternalTeam={allInternalTeam}
              allExternalTeam={allExternalTeam}
              project_id={project_id}
              driveFolders={driveFolders}
              sortOption={sortOption}
              handleSortChange={handleSortChange}
              handleFolderClick={handleFolderClick}
              handleFolderShare={handleFolderShare}
              projectDetail={projectDetail}
              editing={editing}
              setEditing={setEditing}
            />
          )}
          {selectedTabIndex === (adminUserInTeam ? 1 : 2) && (
            <FinanceTabs
              sheetIdInitial={sheetIdInitial}
              setSheetIdInitial={setSheetIdInitial}
              sheetData={sheetData}
              setSheetData={setSheetData}
            />
          )}
          {selectedTabIndex === (adminUserInTeam ? 2 : 1) && (
            <KeyLearningsTab project_id={project_id} />
          )}
        </div>
      </Grid>
      <div
        className="float-end"
        style={{ marginRight: "1.5rem", marginTop: "1.5rem" }}
      >
        <h6
          style={{
            marginRight: "0.5rem",
            color: "black",
            fontFamily: "Poppins",
            fontWeight: "600",
            backgroundColor: "#f2f2f2",
          }}
          onClick={handleNavigateHome}
          className="btn "
        >
          Home
        </h6>
        {projectDetail &&
          projectDetail.publishproject !== null &&
          projectDetail.publishproject === "n" && (
            <h6
              onClick={handlePublishProject}
              className="btn "
              style={{
                marginRight: "1rem",
                color: "black",
                fontFamily: "Poppins",
                fontWeight: "600",
                backgroundColor: "#f2f2f2",
              }}
            >
              Publish Project
            </h6>
          )}
      </div>
    </>
  );
};

export default ProjectTabsHandler;
